.article {
  word-wrap: break-word;
}

/* Landing page subheadings max out at 640px */
.article.landing h2 > p,
.article.landing div[data-markdown-heading='2'] + p {
  max-width: 640px;
}

/* Leaving this in to make the linter happy */
.article.requirements {
}

.article.landing div[data-markdown-heading='2'] {
  margin-bottom: var(--dex-spacing-1x);
}

.article img {
  max-width: 100%;

  background-size: cover;
  background-position: center;
}

/* Paragraphs */
.article p + p {
  margin-top: calc(2 * var(--dex-spacing-1x));
}

.article ul + p,
.article ol + p {
  margin-top: calc(2 * var(--dex-spacing-1x));
}

/* List CSS */
.article ol,
.article ul {
  list-style: none;
  margin: calc(2 * var(--dex-spacing-1x)) 0 var(--dex-spacing-1x) 0;
  padding: 0 0 0 calc(3 * var(--dex-spacing-1x));
}

.article ol ol,
.article ul ul,
.article ol ul,
.article ul ol {
  margin-top: var(--dex-spacing-1x);
}

.article ul {
  list-style-type: disc;
}

.article ol {
  list-style-type: decimal;
}

.article ol ol {
  list-style-type: lower-latin;
}

.article ol ol ol {
  list-style-type: lower-roman;
}

.article ol ol ol ol {
  list-style-type: decimal;
}

.article li {
  margin: 0 0 var(--dex-spacing-1x) 0;
  padding: 0;
  color: var(--core-text-10-color);
}

.article ol > li {
  padding-left: calc(0.5 * var(--dex-spacing-1x));
}

.article ul > li::marker {
  color: var(--core-text-30-color);
}

.article ol > li::marker {
  color: var(--core-text-20-color);
}

/* Link CSS */
.markdown-link {
  color: var(--core-emphasis-text-color);
  text-decoration: none;
  text-underline-position: under;
}

.markdown-link:hover,
.markdown-link:focus-visible {
  text-decoration: underline;
  outline: transparent;
}

/* Typography */
.article em {
  font-style: italic;
}

.article strong {
  font-weight: 600;
}

/* Inline code blocks */
.inline-code {
  font-size: 15px;
  background-color: var(--core-divider-20-color);
  word-break: break-word;
}

.article td .inline-code {
  font-size: 13px;
}

.article.text-variant-20 .inline-code {
  font-size: 13px;
}

.markdown-link > .inline-code {
  color: var(--core-emphasis-text-color);
}

.markdown-link:hover > .inline-code,
.markdown-link:focus-visible > .inline-code {
  color: var(--core-emphasis-10-color);
}

.emphasis,
.emphasis,
.emphasis,
.emphasis {
  color: var(--core-emphasis-text-color);
}

/* blockquote */
.article blockquote {
  margin: calc(1.5 * var(--dex-spacing-1x)) 0;
  padding: 0 0 0 calc(2 * var(--dex-spacing-1x));
  border-left: 1px solid var(--core-text-30-color);
}

/* page color theme */
.article,
.article.blue {
  --doc-page-color-bg-theme: var(--core-emphasis-40-color);
  --doc-page-color-text-theme: var(--core-emphasis-text-color);
}

.article.teal {
  --doc-page-color-bg-theme: var(--core-teal-40-color);
  --doc-page-color-text-theme: var(--core-teal-text-color);
}

.article.pink {
  --doc-page-color-bg-theme: var(--core-pink-40-color);
  --doc-page-color-text-theme: var(--core-pink-text-color);
}

.article.orange {
  --doc-page-color-bg-theme: var(--core-orange-40-color);
  --doc-page-color-text-theme: var(--core-orange-text-color);
}

.article.purple {
  --doc-page-color-bg-theme: var(--core-purple-40-color);
  --doc-page-color-text-theme: var(--core-purple-text-color);
}
